<template>
    <div id="siteApply">
        <div class="admin_main_block">
            <div class="user_temp_title">分站申请</div>
            <div class="admin_table_main">
                <div class="edit_password_from">
                    <el-form label-width="100px" :model="ruleForm" :rules="rules">
                        <el-form-item label="网站名称" size="small" prop="name">
                            <el-input v-model="ruleForm.name" size="small" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="备案域名" size="small" prop="web">
                            <el-input v-model="ruleForm.web" size="small" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="模板选择" size="small" prop="color">
                            <el-select v-model="ruleForm.color">
                                <el-option 
                                    v-for="item in typeList"
                                    :label="item.label"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item size="small">
                            <el-button size="small" type="primary">提交</el-button>
                            <el-button size="small" type="default">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            typeList: [
                {
                    label: '红色',
                    value: 1
                },
                {
                    label: '天蓝色',
                    value: 2
                },
                {
                    label: '黄色',
                    value: 3
                }
            ],
            ruleForm: {
                color: '',
                name: '',
                web: '',
            },
            rules: {
                name: [
                    { required: true, message: '网站名称不能为空', trigger: 'blur' }
                ],
                web: [
                    { required: true, message: '备案域名不能为空', trigger: 'blur' }
                ],
                color: [
                    { required: true, message: '模板颜色不能为空', trigger: 'change' }
                ]
            }
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#siteApply{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        min-height: 855px;
        border-radius: 4px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .edit_password_from{
            margin-top: 40px;
            padding-bottom: 30px;
            padding-left: 70px;
            .el-input,.el-select{
                width: 40%;
            }
            .el-form-item{
                margin-bottom: 40px;
            }
        }
    }
}
</style>